.footer_div{
    display: flex;
    flex-wrap: nowrap;
    /* justify-content: space-between; */
    gap: 40px;
}
a{
    text-decoration: none !important;
    color: black !important;
}

.footer_logo {
    width: 180px;
    height: 120px;
}
@media screen and (max-width:768px) {
    .footer_div{
        justify-content: space-between;
        
    }   
}