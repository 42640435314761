.form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .login_row{
    min-height: 70vh;
    display:flex ;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
  .form button {
    align-self: flex-end;
    width: 100%;
}
  .password-toggle-btn{
    margin-right: 10px;
  }
  .flex-column > label {
    color: #151717;
    font-weight: 600;
  }
  
  .inputForm {
    border: 1.5px solid #ecedec;
    border-radius: 10px;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    transition: 0.2s ease-in-out;
  }
  .inputForm:focus{
    border: none !important;
    outline: none !important;
  }
  .inputForm .input:focus, .inputForm input:valid {
    border: none !important;
    outline: none;
}
  .inputForm input:focus{
    border: none !important;
    outline: none !important;
  }
  .input {
    margin-left: 10px;
    border-radius: 10px;
    border: none;
    height: 100%;
  }
  
  .input:focus {
    outline: none;
  }
  
  .inputForm:focus-within {
    border: 1.5px solid #2d79f3;
  }
  
  .flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
  }
  
  .flex-row > div > label {
    font-size: 14px;
    color: black;
    font-weight: 400;
  }
  
  .span {
    font-size: 14px;
    margin-left: 5px;
    color: #2d79f3;
    font-weight: 500;
    cursor: pointer;
  }
  
  .button-submit {
    margin: 20px 0 10px 0;
    background-color: white;
    border: none;
    color: black;
    font-size: 15px;
    font-weight: 500;
    border-radius: 10px;
    height: 50px;
    cursor: pointer;
  }
  
  .button-submit:hover {
    background-color: black;
    color: white;
    border: 1px solid white;
    transition: all linear 0.2s;
  }

  .p {
    text-align: center;
    color: black;
    font-size: 14px;
    margin: 5px 0;
  }
  
  .btn {
    margin-top: 10px;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    gap: 10px;
    border: 1px solid #ededef;
    background-color: white;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }
  
  .btn:hover {
    border: 1px solid #2d79f3;
    ;
  }
  .register_btn{
    color: royalblue;
    font-size: 16px;
    border-bottom: 1px solid royalblue  ;
  }
      
.title {
    font-size: 28px;
    color: black;
    font-weight: 600;
    letter-spacing: -1px;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 30px;
  }

  .title::before,
  .title::after {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    border-radius: 50%;
    left: 0px;
    background-color: black;
  }

  .title::before {
    width: 18px;
    height: 18px;
    background-color: black;
  }

  .title::after {
    width: 18px;
    height: 18px;
    animation: wave 1.5s linear infinite;
    transform-origin: center;
  }

  @keyframes wave {
    0% {
      transform: scale(0.5);
      opacity: 1;
    }
    100% {
      transform: scale(2.5);
      opacity: 0;
    }
  }