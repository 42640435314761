@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

*{
  font-family: "Roboto", sans-serif !important;
  font-style: normal !important;
}

body{
  padding: 0px; margin: 0px;
  background-color: black;
  color: white;
  box-sizing: border-box;
  overflow-x: hidden ;
}

.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25D366;
  color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 1000;
  transition: transform 0.2s;
}

.whatsapp-button:hover {
  transform: scale(1.1);
}

a{
  text-decoration: none !important;
}
.cursor{
  cursor: pointer;
}
.error{
    font-family: 'Montserrat', sans-serif;
    color: black;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.1rem;
    margin-top: 0.5rem;
    padding: 5px 10px;
    background-color: white;
  }

  .btn_loading {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
  }
  
  .spinner {
    width: 2rem;
    height: 2rem;
    border-top: 4px solid black;
    border-left: 4px solid black;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  .button-submit:hover .spinner{
    border-top: 4px solid white;
    border-left: 4px solid white;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  