.h_box_main {
    position: relative;
    width: 100%;
    margin: auto;
    display: flex;
    gap: 10px !important;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.h_box_main::-webkit-scrollbar {
    display: none;
}

.card {
    background-color: #1a1a1a; 
    color: white !important;
    overflow: hidden;
    border: none;
    flex-shrink: 0;
    /* transition: box-shadow 0.3s ease-in-out; */
}

.card_img {
    height: 270px;
    border-radius: 20px;
    box-shadow: 10px 10ox 10px #F2F0F1;
    position: relative;
  overflow: hidden !important;
    display: flex;
    justify-content: center !important;
    /* align-items: center !important; */
}

.card_img .overlay {
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    transition: 0.3s ease;
  }
  
  .card_img:hover .overlay {
    left: 0;
  }
  
.card:hover .card_img img {
    cursor: pointer;
    transition: all linear 1.5s;
}

.card:hover {
    box-shadow: 0 0px 2px 1px white !important;
    transition: all linear 0.3s;
    transform: none !important;
    cursor: pointer;
}

.card img {
    width: 100% ;
    max-height: 100% ;
}

.card_title {
    color: white;
    text-transform: capitalize;
    font-weight: 500;
    margin-top: 10px;
    text-align: center;
}

.final_price {
    color: white;
    text-align: center;
    font-weight: 700;
}

.discounted_price {
    font-weight: 600;
}

.discount {
    background-color: white !important;
    padding: 2px 7px !important;
    border-radius: 10px !important;
    color: black !important;
    font-weight: 500 !important;
    font-size: 15px !important;
}
@media screen and (max-width:768px) {
    .card_img {
        height: 200px;
    }   
}