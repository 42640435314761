.collection_card{
    height: 200px !important;
    width: fit-content;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.collection_card:hover{
    box-shadow: 0 0px 2px 1px white !important;
    transition: all linear 0.3s;

}
.collection_card .collection_image {
    max-width: 90%;
    max-height: 100%;
}
.collection_card:hover .collection_image {
    transform: scale(1.06);
    transition: all linear 0.7s;
}

