.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    color: white !important;
    text-align: center;
    font-size: 18px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

.offer {
    font-size: large;

}