/* Ensure that the iframe covers the entire background */
.contact-section {
    position: relative;
    min-height: 100vh; 
    width: 100%;
  }
  
  .map-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border: none;
  }
  
  .contact-content {
    position: relative;
    z-index: 1;
background-color: rgba(0,0,0, 0.4);
    /* background-color: rgba(255, 255, 255, 0.8); */
    padding: 5px;
    border-radius: 10px;
    max-width: fit-content;
    margin: auto;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
  
  /* Make sure content adjusts and does not overflow below */
  .contact-content .container {
    margin-bottom: 10px; /* Add bottom margin to avoid clipping by footer */
  }
  
  /* Ensure the form adjusts based on content size */
  form {
    min-height: fit-content; /* Example height, can be adjusted based on form size */
  }
  .contact_btn{
    width: fit-content;
    padding: 10px;
  }