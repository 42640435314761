.faq_head1{
    font-size: 30px;
    font-weight: 700;
    color: #fff;
}
.faq_btn{
    text-transform: uppercase;
    color: #FFFF;
    /* background-color: rgba(60, 114, 252, 0.9); */
    font-size: 12px;
    font-weight: 500;
    padding: 15px 25px;
    border: none;
    outline: none;
    border-radius: 0px;
}
.faq_btn:hover{
  color: #FFFF;
  box-shadow: 0px 0px 7px rgb(60, 114, 252);
}

.faq_head_detail{
    text-align: center;
    color: rgb(143, 141, 160);
}
.faq_box{
    width: 145px;
    height: 145px;
    margin-bottom: 10px;
    color: #fff;
    text-transform: capitalize;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: rgb(60, 114, 252); */
}
.faq_box_number{
        font-size: 40px;
        font-weight: 700;
}
.faq_box p{
    margin: 0px;
    text-align: center;
}

.accordion-button::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-plus' viewBox='0 0 16 16'%3E%3Cpath d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3E%3C/svg%3E");
    transition: all 0.5s;
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-dash' viewBox='0 0 16 16'%3E%3Cpath d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E");
  }
  .accordian_col{
    width: 80%;
  }
  .accordian_counting{
    color: rgb(60, 114, 252);
    font-weight: 700;
    margin-right: 10px;

  }
  .accordion-header{
    background-color: transparent
     !important;
    }
    

  @media screen and (max-width:768px) {
    .accordian_col{
        width: 95%;
    }
  }
  @media screen and (max-width:992px) {
    .faq_row_direction{
        display: flex;
        flex-direction: column-reverse;
    }
  }